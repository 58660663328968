import React from "react";
import SocialMedia from "../common/SocialMedia";

function Footer() {
    return (
        <div>
        <SocialMedia/>
        </div>
    );
}

export default Footer;